import React, { Component } from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import { connect } from "react-redux";
import { title, informationSaved, failed } from "../common/misc";
import { toast } from "react-toastify";
import AlertError from "../common/alertError";
import AlertSuccess from "../common/alertSuccess";
import Select from "react-select";
import { Link } from "react-router-dom";
import { getQuestion, loadAllQuestion } from "../../store/question";
import { getSection, loadAllSection } from "../../store/section";
import {
  addQuestionAssessment,
  getAssessment,
  updatedQuestionAssessment,
  loadDetailAssessment,
} from "../../store/assessment";
import _ from "lodash";
import seUploadFun from "../services/s3Services";

const initialState = {
  photoStatus: false,
  inputs: [1],
  extra_textbox: false,
  is_required: false,
  is_score: false,
  type: [
    { id: 0, name: "text", label: "Input Text (For short answer)" },
    { id: 1, name: "text_area", label: "Text Area (For long answer)" },
    { id: 2, name: "free_text_pill_box", label: "Free Text Pill Box" },
    { id: 3, name: "dropdown", label: "Dropdown" },
    { id: 4, name: "multiselect_dropdown", label: "Multi Select Dropdown" },
    { id: 5, name: "radio", label: "Radio" },
    { id: 6, name: "checkbox", label: "Checkbox" },
    { id: 7, name: "read_only", label: "Read Only" },
    { id: 8, name: "doc", label: "PDF/Image" },
  ],

  data: {
    question: "",
    section: {},
    question_group: {},
    parent_question_id: {},
    title1: "",
    score1: "",
    display_order: "",
    response_type: "",
  },
  errors: { question: "" },
};

class AddQuestion extends Form {
  state = initialState;
  schema = {
    question: Joi.string()
      .required()
      .label("Write Your Question")
      .error(() => {
        return { message: "Question field is required" };
      }),
    response_type: Joi.string()
      .required()
      .label("Response Type")
      .error(() => {
        return { message: "Response Type field is required" };
      }),
    section: Joi.object().allow(""),
    question_group: Joi.object().allow(""),
    parent_question_id: Joi.object().allow(""),
    title1: Joi.string().allow(""),
    score1: Joi.string().allow(""),
    display_order: Joi.number()
      .integer()
      .required()
      .label("Display Order")
      .error((err) => {
        err.forEach((e) => {
          switch (e.type) {
            case "number.integer":
              e.message = "Display Order must be integer";
              break;
            case "number.base":
              e.message = "Display Order field is required";
          }
        });
        return err;
      }),
  };
  doSubmit = () => {
    const id = this.props.id;
    const payload = [];
    var formdata;
    this.state.data.score1 && this.state.data.title1
      ? this.state.inputs.forEach((element) => {
          const obj = {
            title: this.state.data["title" + element],
            score: this.state.data["score" + element],
          };
          payload.push(obj);
        })
      : this.state.data.title1 &&
        this.state.inputs.forEach((element) => {
          const obj = {
            title: this.state.data["title" + element],
          };
          payload.push(obj);
        });
    const {
      question,
      section,
      question_group,
      parent_question_id,
      display_order,
      response_type,
    } = this.state.data;
    const { is_required, extra_textbox, image } = this.state;
    {
      payload.length > 0
        ? (formdata = {
            question: question,
            response_type: response_type,
            question_group: question_group.value,
            parent_question_id: parent_question_id.value,
            section: section.value,
            is_required: is_required,
            extra_textbox: extra_textbox,
            options: response_type === "doc" ? [{ title: image }] : payload,
            display_order: display_order,
          })
        : (formdata = {
            question: question,
            response_type: response_type,
            question_group: question_group.value,
            parent_question_id: parent_question_id.value,
            section: section.value,
            is_required: is_required,
            extra_textbox: extra_textbox,
            display_order: display_order,
            options: response_type === "doc" ? [{ title: image }] : payload,
          });
    }
    if (this.props.edit_id) {
      this.props.updatedQuestionAssessment(
        id,
        this.props.edit_id,
        { questions: formdata },
        this.editCallback
      );
    } else {
      this.props.addQuestionAssessment(
        id,
        { questions: formdata },
        this.callback
      );
    }
  };

  callback = (res) => {
    if (res && res.status === 200) {
      this.props.loadDetailAssessment(this.props.id);
      this.props.toggleAddUser();
      toast(<AlertSuccess message={informationSaved} />);
      this.setState(initialState);
    } else {
      toast(<AlertError message={failed} />);
      this.setState(initialState);
    }
  };
  editCallback = (res) => {
    if (res && res.status === 200) {
      this.props.loadDetailAssessment(this.props.id);
      this.props.toggleEditUser();
      toast(<AlertSuccess message={informationSaved} />);
      this.setState(initialState);
    } else {
      toast(<AlertError message={failed} />);
      this.setState(initialState);
    }
  };
  toggleMandatory = () => {
    this.setState({
      is_required: !this.state.is_required,
    });
  };
  toggleScore = () => {
    this.setState({
      is_score: !this.state.is_score,
    });
  };
  toggleExtra = () => {
    this.setState({
      extra_textbox: !this.state.extra_textbox,
    });
  };
  addMore = (e) => {
    e.preventDefault();
    const { inputs } = this.state;
    const NewNumber = inputs.length + 1;
    inputs.push(NewNumber);
    this.setState({ inputs });

    const title = "title" + NewNumber;
    const score = "score" + NewNumber;

    const newData = {};
    //const newArr = {};

    newData[title] = "";
    newData[score] = "";

    this.schema[title] = Joi.string().allow("");
    this.schema[score] = Joi.string().allow("");
  };
  removeLast = (e) => {
    e.preventDefault();
    const { inputs } = this.state;
    const removeNumber = inputs.length;
    inputs.pop();
    this.setState({ inputs });
    const title = "title" + removeNumber;
    const score = "score" + removeNumber;

    delete [title];
    delete [score];
  };

  componentDidMount = () => {
    this.props.loadAllSection();
    this.props.loadAllQuestion();
  };

  changeBodyClass = () => {
    document.body.classList.add("active");
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.data && this.props.data !== prevProps.data) {
      const inputs = _.range(1, this.props.data.options.length + 1);
      const parent =
        this.props.detail &&
        this.props.detail.data &&
        this.props.detail.data.questions.find(
          (e) => e._id === this.props.data.parent_question_id
        );
      const newData = {};
      if (inputs.length > 1) {
        newData.question = this.props.data.question && this.props.data.question;
        newData.response_type =
          this.props.data.response_type && this.props.data.response_type;
        newData.display_order =
          this.props.data.display_order &&
          parseInt(this.props.data.display_order);
        newData.parent_question_id = {
          value: parent && parent._id,
          label: parent && parent.question,
        };
        newData.question_group = {
          value:
            this.props.data.question_group &&
            this.props.data.question_group._id,
          label:
            this.props.data.question_group &&
            this.props.data.question_group.name,
        };
        newData.section = {
          value: this.props.data.section && this.props.data.section._id,
          label: this.props.data.section && this.props.data.section.name,
        };

        const is_required = this.props.data.is_required;
        const extra_textbox = this.props.data.extra_textbox;
        const is_score = this.props.data.options[0].score ? true : false;
        inputs.forEach((element, i) => {
          newData["title" + element] = this.props.data.options[i].title;
          newData["score" + element] = this.props.data.options[i].score;
          title1: Joi.string().allow("");
          score1: Joi.string().allow("");
          this.schema["title" + element] = Joi.string().allow("");
          this.schema["score" + element] = Joi.string().allow("");
        });
        this.setState({
          inputs,
          is_score: is_score,
          data: newData,
          is_required: is_required,
          extra_textbox: extra_textbox,
        });
      }
      if (this.props.data.response_type === "doc") {
        
        this.props.data.options.length > 0 &&
          this.setState({
            image: this.props.data.options[0].title,
            displayImage:
              process.env.REACT_APP_S3URL + this.props.data.options[0].title,
          });
      }
      if (inputs.length === 1) {
        const data = { ...this.state.data };
        data.question = this.props.data.question;
        data.display_order = this.props.data.display_order;
        data.response_type = this.props.data.response_type;
        data.question_group = {
          value:
            this.props.data.question_group &&
            this.props.data.question_group._id,
          label:
            this.props.data.question_group &&
            this.props.data.question_group.name,
        };
        data.parent_question_id = {
          value: parent && parent._id,
          label: parent && parent.question,
        };
        data.section = {
          value: this.props.data.section && this.props.data.section._id,
          label: this.props.data.section && this.props.data.section.name,
        };
        data.title1 = this.props.data.options[0].title;
        data.score1 = this.props.data.options[0].score;

        const is_required = this.props.data.is_required;
        const extra_textbox = this.props.data.extra_textbox;
        this.setState({
          inputs,
          data,
          is_required: is_required,
          extra_textbox: extra_textbox,
        });
      }
      if (inputs.length === 0) {
        const data = {
          question: this.props.data.question && this.props.data.question,
          response_type:
            this.props.data.response_type && this.props.data.response_type,
          display_order:
            this.props.data.display_order && this.props.data.display_order,
          question_group: {
            value:
              this.props.data.question_group &&
              this.props.data.question_group._id,
            label:
              this.props.data.question_group &&
              this.props.data.question_group.name,
          },
          parent_question_id: {
            value: parent && parent._id,
            label: parent && parent.question,
          },
          section: {
            value: this.props.data.section && this.props.data.section._id,
            label: this.props.data.section && this.props.data.section.name,
          },
        };
        const is_required = this.props.data.is_required;
        const extra_textbox = this.props.data.extra_textbox;
        this.setState({
          inputs,
          data,
          is_required: is_required,
          extra_textbox: extra_textbox,
        });
      }
    }
  };
  customStyles = {
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "rgb(95, 50, 187, 10%)",
        color: "##621ac0",
      },

      color: state.isSelected ? "#621ac0" : "black",
      backgroundColor: state.isSelected
        ? "rgb(95, 50, 187, 10%)"
        : provided.backgroundColor,
      //backgroundColor: state.isSelected ? "rgb(95, 50, 187, 10%)" : "white",
    }),
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "#6119c0" : "#bdbdbd",
      boxShadow: "0 !important",
      "&:hover": {
        borderColor: "#6119c0",
      },
      "&:focus": {
        borderColor: "#6119c0",
      },
    }),
    multiValueRemove: (base, state) => ({
      ...base,
      "&:hover": {
        backgroundColor: "rgb(95, 50, 187, 10%)",
        color: "#6119c0",
      },
    }),
  };
  handleOnChange(e) {
    const data = { ...this.state.data };
    data.response_type = e.target.name;
    this.setState({ data });
  }
  toggleClose = () => {
    this.setState({
      inputs: [1],
      is_required: false,
      extra_textbox: false,
      is_score: false,
      data: {
        question: "",
        section: {},
        question_group: {},
        parent_question_id: {},
        title1: "",
        score1: "",
        display_order: "",
        response_type: "",
      },
      errors: {},
    });
    this.props.toggleEditUser();
  };
  toggleAddClose = () => {
    this.setState({
      inputs: [1],
      is_required: false,
      extra_textbox: false,
      is_score: false,
      data: {
        question: "",
        section: {},
        question_group: {},
        parent_question_id: {},
        title1: "",
        score1: "",
        display_order: "",
        response_type: "",
      },
      errors: {},
    });
    this.props.toggleAddUser();
  };
  fileUpload = (e) => {
    this.setState({ photoStatus: "Loading" });
    const fileName = "assesment/doc/" + Date.now();
    const res = seUploadFun(e.target.files[0], fileName);
    res.then((result) => {
      const image = result.key;
      this.setState({
        photoStatus: "Uploaded successfully. Click here to change",
        image,
        displayImage: process.env.REACT_APP_S3URL + result.key,
      });
    });
  };
  render() {
    const { question, detail } = this.props;
    const { section } = this.props;
    //console.log("dadasd", this.state.data);
    return (
      <>
        <div
          className={
            this.props.isShow === true || this.props.editShow === true
              ? "offcanvas offcanvas3 offcanvas-left p-10 offcanvas-on "
              : "offcanvas offcanvas3 offcanvas-left p-10"
          }
          style={{ height: "100%", "overflow-y": "auto" }}
        >
          <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
            <h3 className="font-weight-bold m-0">Add Question</h3>
            {this.props.edit_id ? (
              <a
                href="javascript:void (0)"
                className="btn btn-xs btn-icon btn-light btn-hover-primary"
                onClick={(e) => {
                  e.preventDefault();
                  this.toggleClose();
                }}
              >
                <i className="ki ki-close icon-xs text-muted"></i>
              </a>
            ) : (
              <a
                href="javascript:void (0)"
                className="btn btn-xs btn-icon btn-light btn-hover-primary"
                onClick={this.toggleAddClose}
              >
                <i className="ki ki-close icon-xs text-muted"></i>
              </a>
            )}
          </div>
          <hr />
          <form action="" onSubmit={this.handleSubmit}>
            <div className="form-group row">
              <div className="col-lg-6">
                <label>Section</label>
                <Select
                  key={
                    this.state.data.section && this.state.data.section.length
                  }
                  onChange={(e) => {
                    const data = { ...this.state.data };
                    if (e) {
                      data.section = e;
                      this.setState({ data });
                    }
                  }}
                  placeholder=""
                  styles={this.customStyles}
                  className="form-groupxx"
                  closeMenuOnSelect={true}
                  components={this.animatedComponents}
                  options={section.map((e) => ({
                    label: e.name,
                    value: e._id,
                  }))}
                  value={this.state.data.section}
                  defaultValue={{ label: 2002, value: 2002 }}
                />
              </div>
              <div className="col-lg-6">
                <label>Question Group</label>
                <Select
                  key={
                    this.state.data.question_group &&
                    this.state.data.question_group.length
                  }
                  onChange={(e) => {
                    const data = { ...this.state.data };
                    if (e) {
                      data.question_group = e;
                      this.setState({ data });
                    }
                  }}
                  placeholder=""
                  styles={this.customStyles}
                  className="form-groupxx"
                  closeMenuOnSelect={true}
                  components={this.animatedComponents}
                  options={question.map((e) => ({
                    label: e.name,
                    value: e._id,
                  }))}
                  value={this.state.data.question_group}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-12">
                <label>Parent Question</label>
                <Select
                  key={
                    this.state.data.parent_question_id &&
                    this.state.data.parent_question_id.length
                  }
                  onChange={(e) => {
                    const data = { ...this.state.data };
                    if (e) {
                      data.parent_question_id = e;
                      this.setState({ data });
                    }
                  }}
                  placeholder=""
                  styles={this.customStyles}
                  className="form-groupxx"
                  closeMenuOnSelect={true}
                  components={this.animatedComponents}
                  options={
                    detail &&
                    detail.data &&
                    detail.data.questions.map((e) => ({
                      label: e.question,
                      value: e._id,
                    }))
                  }
                  value={this.state.data.parent_question_id}
                  defaultValue={{ label: 2002, value: 2002 }}
                />
              </div>
            </div>
            {this.renderInput("question", "Write Your Question")}
            {this.renderInput("display_order", "Display Order", "Number")}
            <div className="form-group row">
              <div className="col-lg-6">
                <label>Mandatory to Answer</label>
                <div className="checkbox-list">
                  <label className="checkbox">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="agree"
                      value=""
                      defaultChecked={this.state.is_required}
                      onClick={this.toggleMandatory}
                    />
                    <span></span>
                  </label>
                </div>
              </div>
              <div className="col-lg-6">
                <label>Extra Textbox</label>
                <div className="checkbox-list">
                  <label className="checkbox">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="agree"
                      value=""
                      defaultChecked={this.state.extra_textbox}
                      onClick={this.toggleExtra}
                    />
                    <span></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Response Type</label>
              <div class="radio-list">
                {this.state.type.map((e, i) => (
                  <label class="radio" key={i}>
                    <input
                      type="radio"
                      onChange={(e) => this.handleOnChange(e)}
                      checked={this.state.data.response_type === e.name}
                      name={e.name}
                    />
                    <span></span>
                    {e.label}
                  </label>
                ))}
              </div>
              {this.state.errors.response_type && (
                <div className="fv-plugins-message-container">
                  <div data-validator="notEmpty" className="fv-help-block">
                    {this.state.errors.response_type}
                  </div>
                </div>
              )}
            </div>
            {this.state.data.response_type === "dropdown" ||
            this.state.data.response_type === "checkbox" ||
            this.state.data.response_type === "multiselect_dropdown" ||
            this.state.data.response_type === "radio" ? (
              <>
                {this.state.data.response_type === "radio" && (
                  <div className="form-group row te">
                    <div className="col-lg-6">
                      <label>Applicable for Scoring</label>
                      <div className="checkbox-list">
                        <label className="checkbox">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="agree"
                            value=""
                            defaultChecked={this.state.is_score}
                            onClick={this.toggleScore}
                          />
                          <span></span>
                        </label>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.inputs.map((element, i) => (
                  <React.Fragment key={element}>
                    <div
                      className="form-group row"
                      style={{ marginBottom: "0.50rem" }}
                    >
                      <div className="col-lg-6">
                        {this.renderInput("title" + element, "Title")}
                      </div>
                      {this.state.is_score === true &&
                      this.state.data.response_type === "radio" ? (
                        <div className="col-lg-4">
                          {this.renderInput("score" + element, "Score")}
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.inputs.length > 1 &&
                        this.state.inputs.length - 1 === i && (
                          <div className="col-lg-2">
                            <a
                              href="javascript:void (0)"
                              onClick={this.removeLast}
                              className="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mt-8"
                              title="Delete"
                            >
                              <span className="svg-icon svg-icon-md">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  width="24px"
                                  height="24px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    strokeWidth="1"
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <rect
                                      x="0"
                                      y="0"
                                      width="24"
                                      height="24"
                                    ></rect>
                                    <path
                                      d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                      fill="#000000"
                                      fillRule="nonzero"
                                    ></path>
                                    <path
                                      d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                      fill="#000000"
                                      opacity="0.3"
                                    ></path>
                                  </g>
                                </svg>
                              </span>
                            </a>
                          </div>
                        )}
                    </div>
                  </React.Fragment>
                ))}

                <div className="row">
                  <div className="col-xl-5  mb-4">
                    <a
                      href="#"
                      onClick={this.addMore}
                      className="add-more-field"
                    >
                      + Add more
                    </a>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {this.state.data.response_type === "doc" && (
              <div className="form-group row">
                <div className="col-lg-12">
                  <label
                    className={this.state.errors.image ? "errorColor" : ""}
                    htmlFor="photograph2"
                  >
                    Upload Image
                  </label>
                  <input
                    accept="image/*,application/pdf"
                    type="file"
                    id="photograph2"
                    className="form-control "
                    onChange={this.fileUpload}
                    disabled={this.state.photoStatus === "Loading"}
                  />
                </div>
              </div>
            )}
            
            {this.state.displayImage && (
              <>
                {this.state.displayImage.split(".").pop() === "pdf" ||
                this.state.displayImage.split(".").pop() === "PDF" ? (
                  <>
                    <embed src={this.state.displayImage} />
                  </>
                ) : (
                  <img src={this.state.displayImage} alt="" />
                )}
              </>
            )}
            <hr />
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
              <div className="my-3 mr-2">
                <span className="text-muted mr-2"></span>
                <a href="#" className="font-weight-bold"></a>
              </div>
              <button
                type="submit"
                className="btn btn-primary font-weight-bolder"
              >
                Save
              </button>
            </div>
          </form>
        </div>
        {this.props.isShow === true ? (
          <div className="offcanvas-overlay"></div>
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  detail: getAssessment(state).detail,
  question: getQuestion(state).question,
  section: getSection(state).section,
});
const mapDispatchToProps = (dispatch) => ({
  loadDetailAssessment: (id) => dispatch(loadDetailAssessment(id)),
  addQuestionAssessment: (id, data, callback) =>
    dispatch(addQuestionAssessment(id, data, callback)),
  loadAllQuestion: () => dispatch(loadAllQuestion()),
  loadAllSection: () => dispatch(loadAllSection()),
  updatedQuestionAssessment: (id, id2, data, callback) =>
    dispatch(updatedQuestionAssessment(id, id2, data, callback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddQuestion);
