// import S3 from "aws-s3";
// const seUploadFun = function (file, newFileName) {
//   const config = {
//     bucketName: process.env.REACT_APP_S3_BUCKET,
//     region: "ap-south-1",
//     accessKeyId: process.env.REACT_APP_S3A,
//     secretAccessKey: process.env.REACT_APP_S3K,
//   };
//   const S3Client = new S3(config);
//   return S3Client.uploadFile(file, newFileName);
// };
// export default seUploadFun;
import axios from 'axios';

const seUploadFun = async (file, newFileName) => {
  try{
      const modifiedName = newFileName + file.name;
      const headers = {
        'auth-token': process.env.REACT_APP_TOKEN,
      }
      if (localStorage.getItem('x-auth-token')) {
        headers['x-auth-token'] = localStorage.getItem('x-auth-token')
      } else if (localStorage.getItem('x-auth-token-doctor')) {
        headers['x-auth-token'] = localStorage.getItem('x-auth-token-doctor')
      }
      const data = new FormData();
      data.append('filename', modifiedName);
      data.append('file',file,modifiedName)
      const response = await axios.request({
        baseURL: process.env.REACT_APP_APIBASE,
        headers,
        url:'document/upload',
        method:'POST',
        data,
      })
    const ret = {key:modifiedName};
    return ret;
  }catch(error){
    return false;
  }
};
export default seUploadFun;